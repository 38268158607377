import { useEffect } from 'react';

const VflOsnabrueck = () => {
  useEffect(() => {
    localStorage.setItem('club_id', 450);
    localStorage.setItem('club_name', 'VfL Osnabrück');

    window.location.assign('/');
  });

  return null;
};

export default VflOsnabrueck;
